<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title>
      Attiva Sottoscrizione
    </v-card-title>
    <v-divider />
    <v-card-text>
      <h3 class="mt-3">Informazioni sottoscrizione</h3>

      <v-row>
        <v-col>
          <span class="d-flex align-center">
            <small class="d-block text--secondary mr-5">
              Sottoscrizione
            </small>
          </span>
          <strong>{{ subscription.code }}</strong>
        </v-col>
        <v-col>
          <span class="d-flex align-center">
            <small class="d-block text--secondary mr-5">
              Piano Sottoscritto
            </small>
          </span>
          <strong>
            {{ plan.name }}
            (
            <em>{{ plan.code }}</em>
            )
          </strong>
        </v-col>
        <v-col>
          <span class="d-flex align-center">
            <small class="d-block text--secondary mr-5">
              Stato
            </small>
          </span>
          <strong>{{ statusMap[subscription.status] }}</strong>
        </v-col>
        <v-col>
          <span class="d-flex align-center">
            <small class="d-block text--secondary mr-5">
              Data Richiesta
            </small>
          </span>
          <strong>{{
            subscription.created_at | momentOr('DD/MM/YYYY HH:mm')
          }}</strong>
        </v-col>

        <v-col>
          <span class="d-flex align-center">
            <small class="d-block text--secondary mr-5">
              Canale
            </small>
          </span>
          <strong>{{ subscription.channel }}</strong>
        </v-col>
      </v-row>

      <v-divider class="my-4" />

      <h3 class="mb-3">Attivazione Sottoscrizione</h3>

      <template v-if="subscription.status === 'waiting'">
        <p>
          Per proseguire con l'attivazione, è necessario l'invio e la verifica
          di un codice OTP da inviare al cliente.
        </p>

        <v-row>
          <FormItem v-model="sender" name="Mittente" required readonly />
          <FormItem v-model="recipient" name="Cellulare Cliente" required />
          <v-col>
            <v-btn
              large
              depressed
              color="success"
              :loading="loading"
              :disabled="!isAssignedToUser"
              @click="onCreateOtp"
            >
              <v-icon left>
                mdi-message-processing
              </v-icon>
              INVIA OTP
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            Anteprima messaggio:<br />
            <code>{{ plan.otp_template }}</code>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="3">
            <v-otp-input v-model="otp" length="6" />
          </v-col>
          <v-col>
            <v-btn
              large
              depressed
              color="success"
              :disabled="disabledConfirm"
              @click="onValidateOtp"
            >
              <v-icon left>
                mdi-emoticon-happy
              </v-icon>
              CONFERMA ATTIVAZIONE
            </v-btn>
            <v-btn
              large
              depressed
              color="warning"
              class="ml-4"
              :disabled="!isAssignedToUser"
              @click="onRefuse"
            >
              <v-icon left>
                mdi-emoticon-sad
              </v-icon>
              CLIENTE RIFIUTA
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-alert type="info" prominent outlined>
          La sottoscrizione si trova in nello stato
          <strong> {{ statusMap[subscription.status] }} </strong>, pertanto non
          è più gestibile. Nessuna operazione necessaria.
        </v-alert>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'plansOtp/getCurrent',
  mutationType: 'plansOtp/SET_CURRENT_FIELDS',
})

const { mapFields: mapFieldsSubscription } = createHelpers({
  getterType: 'plansSubscriptions/getCurrent',
  mutationType: 'plansSubscriptions/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketSubscriptionUpdate',
  components: { FormItem },
  data: () => ({
    otpSent: false,
  }),
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('plansSubscriptions', {
      subscription: 'current',
    }),
    ...mapState('plans', {
      plan: 'current',
    }),
    ...mapGetters('plansSubscriptions', ['statusMap']),
    ...mapState('plansOtp', {
      loading: 'loading',
      currentOTP: 'current',
    }),
    ...mapFields([
      'sender',
      'recipient',
      'customer_id',
      'plans_subscription_id',
      'otp',
    ]),
    ...mapFieldsSubscription(['status']),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
    }),
    disabledConfirm() {
      return !this.otpSent || !this.otp || this.otp.length !== 6
    },
  },
  watch: {
    plan: {
      immediate: true,
      handler: 'onPlan',
    },
  },

  mounted() {
    this.setCurrentPlan(this.subscription.plan_id)
  },

  methods: {
    ...mapActions('plans', {
      setCurrentPlan: 'setCurrent',
    }),
    ...mapActions('plansOtp', {
      createOtp: 'create',
      loadOtp: 'setCurrent',
      validateOtp: 'update',
    }),
    ...mapActions('plansSubscriptions', {
      reloadSubscription: 'setCurrent',
      updateSubscription: 'update',
    }),

    async onCreateOtp() {
      const code = await this.createOtp()
      await this.loadOtp(code.id)
      this.otpSent = true
    },

    async updateSubscriptionStatus(status) {
      this.status = status

      await this.updateSubscription()
      await this.reloadSubscription(this.subscription.id)
    },

    async onValidateOtp() {
      const code = await this.validateOtp()
      await this.loadOtp(code.id)
      await this.updateSubscriptionStatus('active')
    },

    async onRefuse() {
      await this.updateSubscriptionStatus('cancel')
    },

    onPlan() {
      this.sender = this.plan.otp_sender
      this.customer_id = this.subscription.customer_id
      this.plans_subscription_id = this.subscription.id
      this.recipient =
        this.customer.mobile_phone_number || this.customer.work_phone_number
    },
  },
}
</script>

<style scoped></style>
