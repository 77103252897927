var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"px-5"},[_c('FormItem',{attrs:{"values":_vm.macrosByContext({
          customerId: _vm.customer_id,
          category: _vm.category,
          subcategory: _vm.subcategory,
        }),"type":"select","name":"Macro","clearable":true,"cols":_vm.cols,"sm":_vm.cols,"md":_vm.cols,"lg":_vm.cols,"xl":_vm.cols},on:{"input":_vm.updateValue},model:{value:(_vm.macro),callback:function ($$v) {_vm.macro=$$v},expression:"macro"}}),_c('FormItem',{attrs:{"values":_vm.categoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          subcategory: _vm.subcategory,
        }),"type":"select","name":"Categoria","clearable":true,"cols":_vm.cols,"sm":_vm.cols,"md":_vm.cols,"lg":_vm.cols,"xl":_vm.cols},on:{"input":_vm.updateValue},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('FormItem',{attrs:{"values":_vm.subcategoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          category: _vm.category,
        }),"type":"select","name":"Sottocategoria","clearable":true,"cols":_vm.cols,"sm":_vm.cols,"md":_vm.cols,"lg":_vm.cols,"xl":_vm.cols},on:{"input":_vm.updateValue},model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }