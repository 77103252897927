<template>
  <div>
    <v-alert v-if="isPendingSolicit" dense type="warning" prominent dismissible>
      <v-row align="center">
        <v-col class="grow">
          <strong>Attenzione</strong>, la lavorazione è stata sollecitata in
          data {{ ticket.solicited_at | momentOr('DD/MM/YYYY', '-') }} alle
          {{ ticket.solicited_at | momentOr('HH:mm', '-') }}
        </v-col>
        <v-col class="shrink">
          <v-btn outlined @click="takeSolicit">
            Prendi in carico Sollecito
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketNotifications',
  components: {},
  computed: {
    ...mapFields(['managed_by', 'status', 'resolution', 'id']),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
      isPendingSolicit: 'isPendingSolicit',
    }),
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...authComputed,
  },
  data: () => ({}),
  methods: {
    ...mapMutations('tickets', {
      updateCurrent: 'SET_CURRENT',
    }),
    ...mapMutations('virtualAgentsSessions', {
      setVirtualAgentData: 'SET_CURRENT',
    }),
    ...mapActions('tickets', ['update']),

    ...mapActions('virtualAgentsSessions', {
      createVirtualAgent: 'create',
    }),

    async takeSolicit() {
      this.updateCurrent({
        solicited_managed_at: new Date(),
      })
      await this.update()
    },
  },
}
</script>

<style scoped>
.actionBtn.v-btn--disabled.theme--light {
  background-color: #d9d9d9 !important;
}
.actionBtn.v-btn--disabled.theme--dark {
  background-color: #323232 !important;
}
</style>
