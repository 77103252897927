<template>
  <v-row class="my-3">
    <v-col>
      <!-- presa in carico -->
      <v-btn
        v-if="canUser('tickets', 'update') && !isAssignedToUser"
        color="green"
        class="py-4 px-8 mr-3 mb-3"
        depressed
        tile
        :block="$vuetify.breakpoint.smAndDown"
        @click="ticketInCharge"
      >
        <v-icon left class="mr-4"> mdi-check-circle </v-icon>
        Prendi in carico
      </v-btn>

      <!-- rilascia lavorazione -->
      <v-btn
        v-if="canUser('tickets', 'update') && isAssignedToUser"
        color="green"
        class="py-4 px-8 mr-3 mb-3"
        depressed
        tile
        :block="$vuetify.breakpoint.smAndDown"
        @click="ticketReleaseCharge"
      >
        <v-icon left class="mr-4"> mdi-check-circle </v-icon>
        Rilascia lavorazione
      </v-btn>

      <v-btn
        v-if="canUser('tickets', 'solicit')"
        :disabled="!canSolicitTicket"
        color="red"
        :dark="!isAssignedToUser"
        class="py-4 px-8 mr-3 mb-3"
        depressed
        tile
        :block="$vuetify.breakpoint.smAndDown"
        @click="ticketSolicit"
      >
        <v-icon left>mdi-alert-decagram-outline</v-icon>
        Sollecita Ticket
      </v-btn>

      <v-dialog
        v-model="dialogReschedule"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="canUser('tickets', 'update')"
            :disabled="!isAssignedToUser"
            color="info"
            :dark="isAssignedToUser"
            class="py-4 px-8 mr-3 mb-3"
            depressed
            tile
            v-bind="attrs"
            :block="$vuetify.breakpoint.smAndDown"
            v-on="on"
          >
            <v-icon left class="mr-4"> mdi-calendar-edit </v-icon>
            Ripianifica App.to
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Ripianifica Ticket</v-card-title>
          <v-card-text>
            <TicketReschedule @submitted="onRescheduled" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'
import TicketReschedule from '@components/tickets/TicketReschedule.vue'

const { mapFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketActions',
  components: { TicketReschedule },
  computed: {
    ...mapFields(['managed_by', 'status', 'resolution', 'id']),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
      canSolicitTicket: 'canSolicitTicket',
    }),
    ...authComputed,
  },
  data: () => ({
    dialogReschedule: false,
  }),
  methods: {
    ...mapMutations('tickets', {
      updateCurrent: 'SET_CURRENT',
    }),
    ...mapMutations('virtualAgentsSessions', {
      setVirtualAgentData: 'SET_CURRENT',
    }),
    ...mapActions('tickets', ['update']),

    ...mapActions('virtualAgentsSessions', {
      createVirtualAgent: 'create',
    }),

    async ticketInCharge() {
      this.updateCurrent({
        managed_by: this.beUsername,
      })
      await this.update()
    },

    async ticketReleaseCharge() {
      this.updateCurrent({
        managed_by: null,
      })
      await this.update()
    },

    async ticketSolicit() {
      this.updateCurrent({
        prioritary: true,
        solicited_at: new Date(),
      })
      await this.update()
    },

    onRescheduled() {
      this.dialogReschedule = false
    },

    async forwardToBot(type) {
      this.setVirtualAgentData({
        ticket_id: this.id,
        type,
      })

      await this.createVirtualAgent()

      // salvo il ticket
      this.updateCurrent({
        status: 'IN LAVORAZIONE',
        resolution: 'Passato a Bot',
      })
      await this.update()
    },
  },
}
</script>

<style scoped>
.actionBtn.v-btn--disabled.theme--light {
  background-color: #d9d9d9 !important;
}
.actionBtn.v-btn--disabled.theme--dark {
  background-color: #323232 !important;
}
</style>
