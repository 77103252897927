<template>
  <div>
    <v-card outlined tile elevation="0" class="mt-6">
      <v-card-title>
        Campi aggiuntivi
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div
          v-for="additional_field in additional_fields"
          :key="additional_field.key"
        >
          <template v-if="Array.isArray(additional_field.rawValue)">
            <v-row>
              <v-col cols="3" xl="2" class="text-body-2 py-6">
                <strong>{{ additional_field.name }}</strong>
              </v-col>
              <v-col class="text-body-2 py-6">
                <v-data-table
                  :headers="getTableHeaders(additional_field.rawValue)"
                  :items="additional_field.rawValue"
                  :items-per-page="15"
                  caption="Dati inseriti"
                >
                </v-data-table>

                <v-btn
                  outlined
                  depressed
                  small
                  color="success"
                  @click.native="downloadData(additional_field)"
                >
                  <v-icon left>
                    mdi-file-excel-outline
                  </v-icon>
                  SCARICA
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </template>
          <template v-else>
            <v-row  v-if="additional_field.rawValue">
              <v-col cols="3" xl="2" class="text-body-2 py-6">
                <strong>{{ additional_field.name }}</strong>
              </v-col>
              <v-col class="text-body-2 py-6">
                <div v-if="additional_field.value">{{ additional_field.value }}</div>
                <div v-if="!additional_field.value">{{ additional_field.rawValue }}</div>
                <div v-if="Array.isArray(additional_field.opts) && additional_field.rawValue"> 
                  <div v-for="opt in additional_field.opts" :key="opt.text">
                    {{ opt.text }}:
                    <span v-for="(value, index) in opt.value" :key="value">
                      <strong>{{ value }}</strong><span v-if="index !== (opt.value.length - 1)">, </span>
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider />
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { upperFirst } from 'lodash/string.js'
import { mapState } from 'vuex'
import { utils, writeFile } from 'xlsx'

export default {
  name: 'TicketShowAdditionalFields',
  components: {},
  data: () => ({
    additional_fields: [],
  }),
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
  },
  mounted() {
    this.additional_fields = this.ticket.additional_fields
  },
  beforeDestroy() {},
  methods: {
    getTableHeaders(rows) {
      const keys = Object.keys(rows[0])
      return keys.map((key) => ({
        value: key,
        text: upperFirst(key),
      }))
    },
    downloadData(field) {
      const workBook = utils.book_new()
      const sheet = utils.json_to_sheet(field.rawValue)

      workBook.SheetNames.push(field.name)
      workBook.Sheets[field.name] = sheet

      writeFile(
        workBook,
        `ticket-data-${this.ticket.external_id.toLowerCase()}.xls`,
        {}
      )
    },
  },
}
</script>

<style scoped></style>
