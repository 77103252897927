<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title>
      Dettagli Lavorazione
    </v-card-title>
    <v-card-text>
      <v-row>
        <FormItem
          v-model="ticket.status"
          name="Stato Lavorazione"
          type="select"
          :values="statuses"
          rules="required"
          required
          :cols="12"
          :sm="8"
          :md="6"
          :lg="6"
          :xl="6"
          readonly
        />
      </v-row>
      <v-row>
        <FormItem
          v-model="ticket.resolution"
          name="Esito Lavorazione"
          type="select"
          :values="resolutions"
          rules="required"
          required
          :cols="12"
          :sm="8"
          :md="6"
          :lg="6"
          :xl="6"
          readonly
        />
      </v-row>
      <v-row>
        <FormItem
          v-model="ticket.customer_notes"
          name="Note Cliente"
          type="textarea"
          :cols="12"
          :sm="10"
          :md="8"
          :lg="8"
          :xl="8"
          :rows="5"
          required
          readonly
        />
      </v-row>
      <v-row>
        <FormItem
          v-model="ticket.operator_notes"
          name="Note Operatore"
          type="textarea"
          :cols="12"
          :sm="10"
          :md="8"
          :lg="8"
          :xl="8"
          :rows="5"
          required
          readonly
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapGetters, mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'

export default {
  name: 'TicketsNotesReadonly',
  components: { FormItem },
  data: () => ({}),
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),

    ...mapGetters('ticketsStatuses', {
      statusesByContext: 'statusesByContext',
    }),
    ...mapGetters('ticketsResolutions', {
      resolutionsByContext: 'resolutionsByContext',
    }),
    statuses() {
      return this.statusesByContext({
        macro: this.activity.macro,
        category: this.activity.category,
      })
    },
    resolutions() {
      return this.resolutionsByContext({
        macro: this.activity.macro,
        category: this.activity.category,
        status: this.status,
      })
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
}
</script>

<style scoped></style>
