<template>
  <Form
    :disabled="!isAssignedToUser || !canUser('customers.assets', 'update')"
    hide-reset
    send-text="Aggiorna"
    :loading="loading"
    @submit="onSubmit"
  >
    <v-card outlined tile elevation="0" class="mt-6">
      <v-card-title>
        Asset Associato
      </v-card-title>

    <v-card-text>
    <v-row>
      <small
        v-if="!isAssignedToUser"
        class="d-block text--secondary text-subtitle-1"
      >
        Prendi in carico il ticket per procedere
      </small>

      <v-col cols="4">
        <p class="text-h6"> {{ asset.brand }} {{ asset.model }} </p>

        <p>
          <strong>{{ asset.serial_code | placeholder('-') }}</strong>
          <br />
          <small>Seriale</small>
        </p>
        <p>
          <strong>{{ asset.imei | placeholder('-') }}</strong>
          <br />
          <small>IMEI/MSISDN</small>
        </p>
        <p>
          <strong>{{ asset.has_kasko ? 'Coperto' : 'Non coperto' }}</strong>
          <br />
          <small>Copertura Kasko</small>
        </p>
        <p>
          <strong>
            {{ asset.plans_subscription.plan.code }}
            {{ asset.plans_subscription.code }}
          </strong>
          <br />
          <small>Sottoscrizione</small>
        </p>
        <p v-if="asset.plans_subscription">
          <strong>
            {{
              asset.plans_subscription.date_start | momentOr('DD/MM/YYYY', '-')
            }}
            -
            {{
              asset.plans_subscription.date_expiration
                | momentOr('DD/MM/YYYY', '-')
            }}
          </strong>
          <br />
          <small>Validità</small>
        </p>
      </v-col>
      <v-col>
        <FormItem
          v-model="status"
          name="Stato Dispositivo"
          type="select"
          :values="statuses"
          rules="required"
          required
          :cols="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
        />
        <FormItem
          v-model="notes"
          name="Note Dispositivo"
          type="textarea"
          rules="required"
          :cols="12"
          :rows="5"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
          required
        />

        <FormCustomersAsset
          v-model="replaced_by"
          name="Dispositivo Sostitutivo"
          :customer_id="ticket.customer_id"
          :asset_id="replaced_by"
          hint="Se l'asset è stato sostituito, specificare il rimpiazzo."
          clearable
          :cols="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
        />
        <v-col>
          <v-btn
            color="secondary"
            :to="{
              name: 'assets_create',
              query: {
                replacementOf: ticket.asset_id,
                ticketId: ticket.id,
                customerId: ticket.customer_id,
                customerUserId: ticket.customer_user_id,
                plansSubscriptionId: ticket.plans_subscription_id,
              },
            }"
          >
            <v-icon left>mdi-swap-vertical-circle-outline</v-icon>
            Inserisci Dispositivo Sostitutivo
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    </v-card-text>
    </v-card>
  </Form>
</template>

<script>
import Form from '@components/forms/Form.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { createHelpers } from 'vuex-map-fields'
import FormCustomersAsset from '@components/forms/FormAssets.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'customersAssets/getCurrent',
  mutationType: 'customersAssets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketAssetForm',
  components: { FormCustomersAsset, FormItem, Form },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('customersAssets', {
      asset: 'current',
      replacements: 'list',
    }),
    ...mapState('customersAssets', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
      statuses: (state) => state.statuses,
    }),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
    }),
    ...mapFields(['status', 'notes', 'replaced_by']),
  },
  methods: {
    ...mapActions('customersAssets', ['update']),
    async onSubmit() {
      if (this.replaced_by) {
        const ok = await this.verifyLocation(this.replaced_by)
        if (!ok) return
      }
      try {
        await this.update()
      } catch (err) {
        console.debug(err)
      }
    },
    async verifyLocation(replacedById) {
      let pass = true
      if (replacedById) {
        const replacement = this.replacements.find((r) => r.id === replacedById)
        const replacementLocation = replacement.customer_location_id
        if (replacementLocation !== this.asset.customer_location_id) {
          const res = await this.$dialog.confirm({
            text: `Il dispositivo sostituitivo ha una sede diversa rispetto al dispositivo precedente.
            La sede del dispositivo sostitutivo verrà allineata. Si desidera continuare?`,
            title: 'Sede diversa',
          })
          pass = res
        }
      }
      return pass
    },
  },
}
</script>

<style scoped></style>
