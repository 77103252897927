<template>
  <v-container>
    <v-row class="px-5">
      <FormItem
        :values="
          macrosByContext({
            customerId: customer_id,
            category: category,
            subcategory: subcategory,
          })
        "
        type="select"
        v-model="macro"
        name="Macro"
        @input="updateValue"
        :clearable="true"
        :cols="cols"
        :sm="cols"
        :md="cols"
        :lg="cols"
        :xl="cols"
      />
      <FormItem
        :values="
          categoriesByContext({
            customerId: customer_id,
            macro: macro,
            subcategory: subcategory,
          })
        "
        type="select"
        v-model="category"
        name="Categoria"
        @input="updateValue"
        :clearable="true"
        :cols="cols"
        :sm="cols"
        :md="cols"
        :lg="cols"
        :xl="cols"
      />
      <FormItem
        :values="
          subcategoriesByContext({
            customerId: customer_id,
            macro: macro,
            category: category,
          })
        "
        type="select"
        v-model="subcategory"
        name="Sottocategoria"
        @input="updateValue"
        :clearable="true"
        :cols="cols"
        :sm="cols"
        :md="cols"
        :lg="cols"
        :xl="cols"
      />
    </v-row>
  </v-container>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapGetters } from 'vuex'
import { head } from 'lodash'
export default {
  name: 'TicketsActivitiesSelector',
  components: {
    FormItem,
  },
  props: {
    cols: {
      type: [Number, String],
      default: 12,
      required: false,
    },
    customer_id: {
      type: String,
      required: false,
    },
    value: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      macro: null,
      category: null,
      subcategory: null,
      activity_id: null,
    }
  },
  computed: {
    ...mapGetters('ticketsActivities', [
      'macrosByContext',
      'categoriesByContext',
      'subcategoriesByContext',
      'activitiesByContext',
    ]),
  },
  watch: {
    value(val) {
      if (!val) {
        this.macro = this.category = this.subcategory = null
      }
    },
  },
  methods: {
    updateValue() {
      if (this.macro && this.category && this.subcategory) {
        const matching = this.activitiesByContext({
          customerId: this.customer_id,
          macro: this.macro,
          category: this.category,
          subcategory: this.subcategory,
        })
        if (matching.length) {
          this.activity_id = head(matching).id
          return this.$emit('input', this.activity_id)
        }
        return this.$emit('input', null)
      }
    },
  },
}
</script>

<style>
</style>
