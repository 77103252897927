<template>
  <BaseTable
    title="Appuntamenti"
    :headers="headers"
    :items="items"
    :total-rows="totalRows"
    :loading="loading"
    :elevation="0"
    :hide-title="hideTitle"
    @refresh="onRefresh"
  >
    <template v-slot:item.date_appointment="{ item }">
      {{ item.date_appointment | momentOr('DD/MM/YYYY HH:mm', '-') }}
    </template>
    <template v-slot:item.description="{ item }">
      {{ item.description | placeholder('-') }}
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'customersAppointments/getFiltersFields',
  mutationType: 'customersAppointments/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerAppointments',
  components: { BaseTable },
  props: {
    showTickets: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ctx: null,
      headers: [
        {
          text: 'Data Appuntamento',
          align: 'start',
          value: 'date_appointment',
        },
        {
          text: 'Fissato da',
          value: 'created_by',
        },
        {
          text: 'Creato il',
          value: 'created_at',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customersAppointments', {
      items: (state) => state.pagination.rows,
      totalRows: (state) => state.pagination.totalRows,
      totalPages: (state) => state.pagination.totalPages,
      loading: (state) => state.pagination.loading,
    }),
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapState('tickets', {
      ticket: (state) => state.current,
    }),
    ...mapFields(['customer_id', 'ticket_id']),
  },
  mounted() {
    const scopes = ['list', 'location']
    this.customer_id = this.customer.id

    if (this.showTickets) {
      this.ticket_id = this.ticket.id
    } else {
      scopes.push('customer')
    }

    this.setScopes(scopes)
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },
  methods: {
    ...mapActions('customersAppointments', ['getItems']),
    ...mapMutations('customersAppointments', {
      setScopes: 'SET_SCOPES',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    onRefresh(ctx) {
      this.ctx = ctx
      this.getItems(this.ctx)
    },
  },
}
</script>
