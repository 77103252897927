<template>
  <BaseTable
    title="Aggiornamenti Ticket"
    :headers="headers"
    :items="items"
    :total-rows="totalRows"
    :loading="loading"
    :elevation="0"
    sortby="logged_at"
    @refresh="onRefresh"
  >
  </BaseTable>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'ticketsHistory/getFiltersFields',
  mutationType: 'ticketsHistory/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketHistoryTable',
  components: { BaseTable },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsHistory', {
      items: (state) => state.pagination.rows,
      totalRows: (state) => state.pagination.totalRows,
      totalPages: (state) => state.pagination.totalPages,
      loading: (state) => state.pagination.loading,
    }),
    ...mapFields(['customer_id', 'ticket_id']),
  },
  mounted() {
    this.ticket_id = this.ticket.id
  },
  beforeDestroy() {
    this.resetFilters()
  },
  data: () => ({
    headers: [
      {
        text: 'Descrizione',
        value: 'description',
      },
      {
        text: 'Valore',
        value: 'value',
      },
      {
        text: 'Utente',
        value: 'user',
      },
      {
        text: 'Data',
        value: 'logged_at',
      },
    ],
  }),
  methods: {
    ...mapActions('ticketsHistory', ['getItems']),
    ...mapMutations('ticketsHistory', {
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    onRefresh(ctx) {
      this.getItems(ctx)
    },
  },
}
</script>

<style scoped></style>
