<template>
  <div>
    <v-tabs background-color="transparent">
      <v-tab>
        <v-icon left> mdi-account </v-icon>
        Dettagli Lavorazione
      </v-tab>

      <v-tab v-if="ticket.asset_id && canUser('tickets', 'update')">
        <v-icon left> mdi-devices </v-icon>
        Asset Associato
      </v-tab>

      <v-tab>
        <v-icon left> mdi-access-point </v-icon>
        Aggiornamenti
      </v-tab>

      <v-tab>
        <v-icon left> mdi-calendar-check </v-icon>
        Appuntamenti
      </v-tab>

      <v-tab v-if="canUser('customers.attachments', 'search')">
        <v-icon left> mdi-file-star-outline </v-icon>
        Allegati
      </v-tab>

      <v-tab v-if="showAdditionalFields">
        <v-icon left>mdi-playlist-check</v-icon>
        Campi aggiuntivi
      </v-tab>

      <v-tab v-if="isHandleSubscriptionShown">
        <v-icon left>mdi-playlist-check</v-icon>
        Sottoscrizione
      </v-tab>

      <v-tab-item>
        <TicketEditForm v-if="canUser('tickets', 'update')" />
        <TicketsNotesReadonly v-else />
      </v-tab-item>
      <v-tab-item v-if="ticket.asset_id && canUser('tickets', 'update')">
        <TicketAssetForm />
      </v-tab-item>
      <v-tab-item>
        <TicketHistoryTable v-if="canUser('tickets_history', 'search')" />
      </v-tab-item>
      <v-tab-item>
        <CustomerAppointments
          v-if="canUser('customers.appointments', 'search')"
          show-tickets
        />
      </v-tab-item>
      <v-tab-item>
        <CustomerAttachments
          v-if="canUser('customers.attachments', 'search')"
          show-tickets
        />
      </v-tab-item>

      <v-tab-item v-if="showAdditionalFields">
        <TicketShowAdditionalFields />
      </v-tab-item>

      <v-tab-item v-if="isHandleSubscriptionShown">
        <TicketSubscriptionUpdate />
      </v-tab-item>
    </v-tabs>

    <v-row v-if="false">
      <v-col>
        <v-list flat>
          <v-list-item-title>Dettagli Ticket</v-list-item-title>
          <ListItem subtitle="Data Inserimento">
            {{ ticket.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Data Ingaggio">
            {{ ticket.received_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Inserito da">
            {{ ticket.created_by | placeholder('-') }}
          </ListItem>

          <ListItem subtitle="Data Presa in carico">
            {{ ticket.managed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="In carico all'operatore">
            {{ ticket.managed_by | placeholder('-') }}
          </ListItem>

          <ListItem
            v-if="ticket.kasko_closed_at"
            subtitle="Data Consegna Sostitutivo"
          >
            {{ ticket.kasko_closed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem
            v-if="ticket.kasko_tobe_closed_by"
            subtitle="Consegna Sostitutivo Entro"
          >
            {{
              ticket.kasko_tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-')
            }}
          </ListItem>

          <ListItem subtitle="Data Chiusura">
            {{ ticket.closed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Chiuso dall'operatore">
            {{ ticket.closed_by | placeholder('-') }}
          </ListItem>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapState } from 'vuex'
import ListItem from '@components/structure/lists/ListItem.vue'
import TicketEditForm from '@components/tickets/TicketEditForm.vue'
import TicketHistoryTable from '@components/tickets/TicketHistoryTable.vue'
import CustomerAttachments from '@components/customers/CustomerAttachments.vue'
import CustomerAppointments from '@components/customers/CustomerAppointments.vue'
import TicketAssetForm from '@components/tickets/TicketAssetForm.vue'
import TicketSubscriptionUpdate from '@components/tickets/TicketSubscriptionUpdate.vue'
import TicketShowAdditionalFields from '@components/tickets/TicketShowAdditionalFields.vue'
import TicketsNotesReadonly from '@components/tickets/TicketNotesReadonly.vue'

export default {
  name: 'TicketBody',
  components: {
    TicketsNotesReadonly,
    TicketSubscriptionUpdate,
    TicketAssetForm,
    CustomerAppointments,
    CustomerAttachments,
    TicketHistoryTable,
    TicketShowAdditionalFields,
    TicketEditForm,
    ListItem,
  },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('customersUsers', {
      user: 'current',
    }),
    ...mapState('customersLocations', {
      location: 'current',
    }),
    ...mapState('plansSubscriptions', {
      subscription: 'current',
    }),
    isHandleSubscriptionShown() {
      return (
        this.ticket.extra?.plan_activation &&
        this.canUser('plans_subscriptions', 'update')
      )
    },
    showAdditionalFields() {
      return this.ticket.additional_fields.length > 0
    },
  },
}
</script>

<style scoped></style>
