<template>
  <v-card elevation="0" outlined tile>
    <Map
      v-if="$vuetify.breakpoint.mdAndUp && ticket.type === 'onsite'"
      height="260px"
      :markers="markers"
      fit-bounds
    />
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6" lg="3" md="6">
          <small class="d-block text--secondary">Identificativo</small>
          {{ ticket.external_id }}
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-dialog
            v-model="customers.dialog"
            scrollable
            max-width="600px"
            @click:outside="
              () => {
                customers.dialog = false
                customers.value = null
              }
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex align-center">
                <small class="d-block text--secondary mr-5">Cliente </small>
                <v-btn
                  v-if="canUser('tickets', 'update') && !isMagicLink"
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <v-card>
              <v-card-title>Riassegna ad un nuovo cliente</v-card-title>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col :cols="10"
                    ><v-row>
                      <FormCustomers
                        v-model="customers.value"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        dense
                      /> </v-row
                  ></v-col>
                  <v-col :cols="2" align-self="center"
                    ><v-btn
                      color="primary"
                      :disabled="
                        !customers.value || customers.value === customer.id
                      "
                      @click="updateCustomer"
                      >Salva</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          {{ customer.common_name }}
          <small class="mr-4">({{ customer.city }})</small>
          <v-btn
            v-if="!isMagicLink"
            icon
            small
            color="primary"
            :to="{
              name: 'customers_detail',
              params: { id: customer.id },
            }"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-dialog
            v-model="activities.dialog"
            scrollable
            max-width="600px"
            @click:outside="
              () => {
                activities.value = null
                activities.dialog = false
              }
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex align-center">
                <small class="d-block text--secondary mr-5">Attività </small>
                <v-btn
                  v-if="canUser('tickets', 'update') && !isMagicLink"
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <v-card>
              <v-card-title>Riassegna nuova attivita'</v-card-title>
              <v-divider></v-divider>
              <v-container
                ><v-row>
                  <v-col>
                    <TicketsActivitiesSelector
                      v-model="activities.value"
                      :customer_id="customer_id"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center"
                    ><v-btn
                      class="ml-3"
                      color="primary"
                      :disabled="!activities.value"
                      @click="updateActivity"
                      >Salva</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          {{ activity.macro }} <v-icon>mdi-chevron-right</v-icon>
          {{ activity.category }} <v-icon>mdi-chevron-right</v-icon>
          {{ activity.subcategory }}
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="subtitle-1">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-dialog
            v-model="customer_users.dialog"
            scrollable
            max-width="600px"
            @click:outside="
              () => {
                customer_users.dialog = false
                customer_users.value = null
              }
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex align-center">
                <small class="d-block text--secondary mr-5">Utente </small>
                <v-btn
                  v-if="canUser('tickets', 'update') && !isMagicLink"
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <v-card>
              <v-card-title>Riassegna ad un nuovo utente</v-card-title>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col :cols="10"
                    ><v-row>
                      <FormCustomersUsers
                        v-model="customer_users.value"
                        :customer_id="customer_id"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        dense
                        :clearable="true"
                      /> </v-row
                  ></v-col>
                  <v-col :cols="2" align-self="center"
                    ><v-btn color="primary" @click="updateCustomerUser"
                      >Salva</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <span v-if="!ticket.customer_user_id"> - </span>
          <span v-else> {{ user.first_name }} {{ user.last_name }} </span>
        </v-col>

        <v-col cols="12" sm="6" md="3" class="text-truncate">
          <small class="d-block text--secondary">Contatti</small>
          <span v-if="!ticket.customer_user_id"> - </span>
          <span v-else>
            {{ user.email }} <br />
            {{ user.mobile_phone_number }}
          </span>
          <span v-if="ticket.customer_callback_num"
            ><br />
            {{ ticket.customer_callback_num }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-dialog
            v-model="assets.dialog"
            scrollable
            max-width="600px"
            @click:outside="
              () => {
                assets.value = null
                assets.dialog = false
              }
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex align-center">
                <small class="d-block text--secondary mr-5">Dispositivo</small>
                <v-btn
                  v-if="canUser('tickets', 'update') && !isMagicLink"
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <v-card>
              <v-card-title>Riassegna nuovo dispositivo</v-card-title>
              <v-divider></v-divider>
              <v-container
                ><v-row dense>
                  <v-col cols="10">
                    <FormAssets
                      v-model="assets.value"
                      :customer_id="customer_id"
                      :customer_user_id="customer_user_id"
                      :cols="12"
                      :sm="12"
                      :md="12"
                      :lg="12"
                      :xl="12"
                      dense
                    />
                  </v-col>
                  <v-col cols="2"
                    ><v-btn
                      class="mt-3"
                      color="primary"
                      :disabled="!assets.value"
                      @click="updateAsset"
                      >Salva</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <span v-if="!ticket.asset_id"> - </span>
          <span v-else>
            {{ asset.brand }} {{ asset.model }} <br />
            <span class="text--secondary subtitle-2">
              <v-icon left class="text--secondary">mdi-barcode</v-icon>
              {{ asset.imei || asset.serial }}
            </span>
          </span>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-dialog
            v-model="subscriptions.dialog"
            scrollable
            max-width="600px"
            @click:outside="
              () => {
                subscriptions.value = null
                subscriptions.dialog = false
              }
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-flex align-center">
                <small class="d-block text--secondary mr-5"
                  >Sottoscrizione</small
                >
                <v-btn
                  v-if="canUser('tickets', 'update') && !isMagicLink"
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            <v-card>
              <v-card-title>Riassegna nuova sottoscrizione'</v-card-title>
              <v-divider></v-divider>
              <v-container
                ><v-row>
                  <v-col :cols="10">
                    <v-row dense>
                      <PlansSubscriptionsSelect
                        v-model="subscriptions.value"
                        :customerid="customer_id"
                        :cols="12"
                        :sm="12"
                        :md="12"
                        :lg="12"
                        :xl="12"
                        dense
                      />
                    </v-row>
                  </v-col>
                  <v-col :cols="2" class="pt-3"
                    ><v-btn
                      color="primary"
                      :disabled="!subscriptions.value"
                      @click="updateSubscription"
                      >Salva</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <strong>{{ subscription.code | placeholder('-') }}</strong
          ><br />
          <span class="text--secondary subtitle-2">
            Validità:
            {{ subscription.date_start | momentOr('MM/YYYY') }}
            <v-icon small>mdi-chevron-right</v-icon>
            {{ subscription.date_expiration | momentOr('MM/YYYY') }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-text class="subtitle-1">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary"> Stato </small>
          <v-chip label color="primary">
            {{ ticket.status | placeholder('-') }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary"> Esito </small>
          <v-chip label>{{ ticket.resolution | placeholder('-') }}</v-chip>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">Appuntamento previsto</small>
          <v-chip label>
            {{ ticket.date_appointment | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">
            Da {{ ticket.tobe_closed_by ? 'chiudere' : 'gestire' }} entro
          </small>
          <v-chip v-if="ticket.tobe_closed_by" label color="warning">
            {{ ticket.tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </v-chip>
          <v-chip v-else label color="warning">
            {{ ticket.tobe_managed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapState } from 'vuex'
import FormAssets from '@components/forms/FormAssets.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'
import Map from '@components/common/Map.vue'
import PlansSubscriptionsSelect from '@components/customer_plans_subscriptions/CustomersSubscriptionsSelect.vue'
import TicketsActivitiesSelector from '@components/tickets/activities/TicketsActivitiesSelector.vue'
import utils from '@mixins/utils.js'

const { mapFields: mapTicketFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketDetails',
  components: {
    FormAssets,
    FormCustomers,
    FormCustomersUsers,
    Map,
    PlansSubscriptionsSelect,
    TicketsActivitiesSelector,
  },
  mixins: [utils],
  data() {
    return {
      activities: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
      assets: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
      customer_users: {
        dialog: false,
        isUpdating: false,
        value: null,
      },
      customers: {
        dialog: false,
        isUpdating: false,
        value: null,
      },
      subscriptions: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapTicketFields([
      'activity_id',
      'asset_id',
      'customer_id',
      'customer_user_id',
      'plans_subscription_id',
    ]),
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('customersUsers', {
      user: 'current',
    }),
    ...mapState('customersLocations', {
      location: 'current',
    }),
    ...mapState('plansSubscriptions', {
      subscription: 'current',
    }),
    ...mapState('customersAssets', {
      asset: 'current',
    }),
    markers() {
      return [this.location]
    },
  },
  methods: {
    ...mapActions('tickets', {
      setCurrentTicket: 'setCurrent',
      updateTicket: 'update',
    }),
    ...mapActions('customers', {
      setCurrentCustomer: 'setCurrent',
    }),
    ...mapActions('ticketsActivities', {
      setCurrentActivity: 'setCurrent',
    }),
    ...mapActions('customersUsers', {
      setCurrentCustomerUser: 'setCurrent',
    }),
    ...mapActions('customersAssets', {
      setCurrentCustomerAsset: 'setCurrent',
    }),
    ...mapActions('plansSubscriptions', {
      setCurrentSubscription: 'setCurrent',
    }),

    async updateActivity() {
      this.activities.isUpdating = true
      this.activity_id = this.activities.value
      await this.updateTicket()
      await this.setCurrentActivity(this.activity_id)
      if (this.customers.isUpdating) {
        this.activities.hasSubmitted = true
      }
      this.activities.dialog = false
      this.activities.value = null
      this.activities.isUpdating = false
    },

    async updateCustomer() {
      this.customers.isUpdating = true

      const res = await this.$dialog.confirm({
        text: `Cambiando il cliente associato, se presente, l'utente associato verrà rimosso.
              In aggiunta sarà richiesto di inserire i dati necessari, continuare?`,
        title: 'Richiesta conferma',
      })
      if (!res) {
        this.customers.isUpdating = false
        return
      }

      /**
       * If the current activity is bound check is coherent or select a coherent one
       */
      if (this.activity.customer_id) {
        if (this.activity.customer_id !== this.customers.value) {
          this.customer_id = this.customers.value

          // If he hasn't already force the user to select a new activity
          if (!this.activities.value) {
            this.activities.dialog = true
            const canProceed = await this.waitForActivity()
            if (!canProceed) {
              this.$dialog.notify.error(`Processo interrotto`, {
                timeout: 2000,
              })
              this.activities.dialog = true
              this.activities.value = null
              this.customers.isUpdating = false
              this.customers.dialog = false
              return
            }
          }
        }
      }

      if (this.subscriptions.customer_id !== this.customers.value) {
        this.customer_id = this.customers.value

        // If he hasn't already force the user to select a new subscription
        if (!this.subscriptions.value) {
          this.subscriptions.dialog = true
          const canProceed = await this.waitForSubscription()
          if (!canProceed) {
            this.$dialog.notify.error(`Processo interrotto`, {
              timeout: 2000,
            })
            this.subscriptions.dialog = true
            this.subscriptions.value = null
            this.customers.isUpdating = false
            this.customers.dialog = false
            return
          }
        }
      }

      this.customer_id = this.customers.value
      // When changing the customer if customer user is assigned avoid conflicts and remove it
      if (this.customer_user_id) this.customer_user_id = null
      // When changing the customer if asset is assigned avoid conflicts and remove it
      if (this.asset_id) this.asset_id = null
      await this.updateTicket()
      await this.setCurrentCustomer(this.customer_id)
      this.customers.value = null
      this.customers.dialog = false
      this.customers.isUpdating = false
    },

    async updateCustomerUser() {
      this.customer_users.isUpdating = true
      this.customer_user_id = this.customer_users.value
      await this.updateTicket()
      if (this.customer_user_id)
        await this.setCurrentCustomerUser(this.customer_user_id)
      this.customer_users.dialog = false
      this.customer_users.value = null
      this.customer_users.isUpdating = false
    },

    async updateSubscription() {
      this.subscriptions.isUpdating = true
      this.plans_subscription_id = this.subscriptions.value
      await this.updateTicket()
      await this.setCurrentSubscription(this.plans_subscription_id)
      if (this.customers.isUpdating) {
        this.subscriptions.hasSubmitted = true
      }
      this.subscriptions.dialog = false
      this.subscriptions.value = null
      this.subscriptions.isUpdating = false
    },

    async updateAsset() {
      this.assets.isUpdating = true
      this.asset_id = this.assets.value
      await this.updateTicket()
      await this.setCurrentCustomerAsset(this.asset_id)
      if (this.customers.isUpdating) {
        this.assets.hasSubmitted = true
      }
      this.assets.dialog = false
      this.assets.value = null
      this.assets.isUpdating = false
    },

    waitForActivity() {
      return new Promise((resolve) => {
        let count = 0
        const interval = setInterval(() => {
          if (this.activities.hasSubmitted) {
            this.activities.hasSubmitted = false
            clearInterval(interval)
            resolve(true)
            // Wait two minutes or wait until the user closes the modal without selecting
          } else if (count === 240 || !this.activities.dialog) {
            clearInterval(interval)
            resolve(false)
          } else {
            count++
          }
        }, 500)
      })
    },

    waitForSubscription() {
      return new Promise((resolve) => {
        let count = 0
        const interval = setInterval(() => {
          if (this.subscriptions.hasSubmitted) {
            this.subscriptions.hasSubmitted = false
            clearInterval(interval)
            resolve(true)
            // Wait two minutes or wait until the user closes the modal without selecting
          } else if (count === 240 || !this.subscriptions.dialog) {
            clearInterval(interval)
            resolve(false)
          } else {
            count++
          }
        }, 500)
      })
    },
  },
}
</script>

<style scoped></style>
