<template>
  <Form
    :disabled="!isAssignedToUser"
    hide-reset
    send-text="Ripianifica"
    @submit="onSubmit"
  >
    <v-row class="mt-6">
      <v-col cols="4">
        <v-row dense>
          <FormItem
            v-show="agendas.length > 1"
            v-model="agenda_id"
            type="select"
            name="Seleziona Agenda"
            hint="Sono state trovate più agende disponibili. Seleziona lo slot  di interesse."
            persistent-hint
            :values="agendas"
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          />
        </v-row>
        <v-row dense class="mt-6">
          <FormItem
            v-model="formatted_date_appointment"
            name="Inizio Intervento"
            readonly
            rules="required"
            required
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          />
        </v-row>
        <v-row dense>
          <FormItem
            v-model="formatted_date_appointment_end"
            name="Fine Intervento"
            readonly
            rules="required"
            required
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          />
        </v-row>
        <v-row dense class="mt-6">
          <FormItem
            v-model="operator_notes"
            name="Note Operatore"
            type="textarea"
            :rows="5"
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          />
        </v-row>
      </v-col>
      <v-col>
        <SlotsCalendar
          ref="calendar"
          :agenda-id="agenda_id"
          @slot-click="onSlotClick"
        />
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import Form from '@components/forms/Form.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { createHelpers } from 'vuex-map-fields'
import SlotsCalendar from '@components/agendas/SlotsCalendar.vue'

const { mapFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketReschedule',
  components: { SlotsCalendar, FormItem, Form },
  computed: {
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
      formatted_date_appointment: 'getFormattedDateAppointment',
      formatted_date_appointment_end: 'getFormattedDateAppointmentEnd',
    }),
    ...mapState('agendas', {
      agendas: (state) => state.list,
    }),
    ...mapFields([
      'operator_notes',
      'customer_id',
      'customer_location_id',
      'date_appointment',
      'date_appointment_end',
      'slot_id',
    ]),
    agendaKeyWatcher() {
      return `${this.customer_id}|${this.customer_location_id}|${this.activity.macro}|${this.activity.category}|${this.activity.subcategory}`
    },
  },
  watch: {
    agendaKeyWatcher: {
      immediate: true,
      handler: 'getActiveAgendas',
    },
  },
  data: () => ({
    agenda_id: null,
  }),
  methods: {
    ...mapActions('tickets', ['update']),
    ...mapActions('agendas', {
      fetchAgendas: 'getDropdownList',
    }),
    ...mapMutations('agendasSlots', {
      highlightSlot: 'HIGHLIGHTS_CURRENT_LIST',
    }),

    async getActiveAgendas() {
      await this.fetchAgendas({
        filters: {
          macro: this.activity.macro,
          category: this.activity.category,
          customer_ids: this.customer_id,
          customers_location_ids: this.customer_location_id,
        },
      })

      if (this.agendas.length && !this.agenda_id)
        this.agenda_id = this.agendas[0].value
    },

    onSlotClick({ nativeEvent, event }) {
      if (event.locked) {
        return
      }

      this.highlightSlot(event.id)

      this.slot_id = event.id
      this.date_appointment = event.start
      this.date_appointment_end = event.end
    },

    async onSubmit() {
      try {
        await this.update()
        this.$emit('submitted', this.id)
        await this.$refs.calendar.loadEvents()
      } catch (err) {
        console.debug(err)
      }
    },
  },
}
</script>

<style scoped></style>
